<template>
    <div class="landing container grid-xl">
        <h1><br />Playlists</h1>
        <div class="columns">
            <div class="column col-sm-12 col-lg-6 col-4" v-for="( playlist, i ) of playlists" :key="i">
                <a class="parallax" @click.prevent="showPlaylist( playlist )" :href="'/playlist/' + playlist.slug" target="_blank" style="cursor: pointer;">
                    <div class="parallax-top-left" tabindex="1"></div>
                    <div class="parallax-top-right" tabindex="2"></div>
                    <div class="parallax-bottom-left" tabindex="3"></div>
                    <div class="parallax-bottom-right" tabindex="4"></div>
                    <div class="parallax-content">
                        <div class="parallax-front">
                            <h2>{{ playlist.name }}<br />{{ playlist.videos.length }} video's</h2>
                        </div>
                        <div class="parallax-back">
                            <img src="/thumb.jpg" class="img-responsive rounded">
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import screenfull from 'screenfull';
    export default {

        data()
        {
            return {};
        },

        computed: {
            playlists()
            {
                return this.$store.getters.playlists;
            }
        },
        methods: {
            showPlaylist( playlist )
            {
                if( screenfull.isEnabled )
                {
                    screenfull.request();
                }
                this.$router.push( '/playlist/' + playlist.slug );
            }
        }
    }
</script>

<style>
    .landing {
    }
</style>