<template>
    <div class="container grid-xl">
        <h1>Beheer playlists</h1>
        <progress class="progress" :value="progress" v-if="progress >= 0" max="100"></progress>
        <div class="columns">
            <div class="column col-sm-12 col-lg-6 col-4" v-for="( playlist, i ) of playlists" :key="i">
                <div class="card">
                    <div class="card-header">
                        <a href="#" class="btn btn-error float-right" @click.prevent="deletePlaylist( playlist )"><i class="icon icon-cross"></i></a>
                        <div class="card-title h5">Playlist: {{ playlist.name }}</div>
                        <div class="card-body">
                            <draggable
                                :list="playlist.videos"
                                :disabled="!enabled"
                                item-key="name"
                                tag="ol"
                                class="list-group"
                                ghost-class="ghost"
                                @start="dragging = true"
                                @end="dragging = false"
                                @change="saveSort( playlist )"
                            >
                                <template #item="{ element }">
                                    <li class="list-group-item" :class="{ 'not-draggable': !enabled }">
                                        <a href="#" class="btn btn-error btn-sm" @click.prevent="deleteVideo( playlist, element )"><i class="icon icon-cross"></i></a> {{ element.name }}
                                    </li>
                                </template>
                            </draggable>
                        </div>
                        <div class="card-footer">
                            <hr>
                            <fileupload :target="api + '/playlists/'+ playlist.id" action="POST" @finish="finishUpload" @progress="progressUpload"></fileupload>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column col-12">
                <form action="" @submit.prevent="createPlaylist"  class="form-horizontal">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title h5">Nieuwe playlist</div>
                            <div class="form-group">
                                <label class="form-label" for="input-example-1">Name</label>
                                <input class="form-input" type="text" id="input-example-1" placeholder="Name" v-model="newPlaylist">
                            </div>
                        </div>
                        <div class="card-footer">
                            <button class="btn btn-primary">Aanmaken</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import draggable from "vuedraggable";
    import FileUpload from 'vue-simple-upload/dist/FileUpload';
    export default {

        components: {
            'fileupload': FileUpload,
            draggable
        },
        data()
        {
            return {
                newPlaylist: '',
                progress: undefined,
                enabled: true,
                dragging: false
            };
        },

        computed: {
            playlists()
            {
                return this.$store.getters.playlists;
            },
            api()
            {
                return window._rootData.apiUrl;
            }
        },
        methods: {
            createPlaylist()
            {
                this.$store.dispatch( 'createPlaylist', this.newPlaylist );
            },
            deletePlaylist( playlist )
            {
                this.$store.dispatch( 'deletePlaylist', {
                    playlist: playlist.id
                } );
            },
            deleteVideo( playlist, video )
            {
                this.$store.dispatch( 'deleteVideo', {
                    playlist: playlist.id,
                    video: video.id
                } );
            },
            progressUpload( e )
            {
                this.progress = undefined;
                if( e < 100 )
                {
                    this.progress = e;
                }
            },
            finishUpload()
            {
                this.$store.dispatch( 'loadPlaylists' );
            },
            saveSort( playlist )
            {
                this.$store.dispatch( 'saveSort', playlist );
            }
        }
    }
</script>

<style>
    .card {
        box-shadow: 0 0.25rem 1rem rgb(48 55 66 / 15%);
    }
    .list-group li {
        cursor:move;
    }
</style>