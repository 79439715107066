import { Store } from 'vuex';
const axios = require('axios').default;

// Create a new store instance.
const store = new Store({
    state: {
        playlists: []
    },
    getters: {
        playlists: state =>
        {
            return state.playlists;
        },
        playlist: state => ( slug ) =>
        {
            return state.playlists.find( playlist => playlist.slug === slug );
        }
    },
    mutations: {
        playlists( state, payload )
        {
            state.playlists = payload;
        }
    },
    actions: {
        loadPlaylists( context )
        {
            axios.get( window._rootData.apiUrl + '/playlists' ).then( function( response )
            {
                context.commit( 'playlists', response.data );
            });
        },
        createPlaylist( context, payload )
        {
            axios.post( window._rootData.apiUrl + '/playlists', { name: payload } ).then( function()
            {
                context.dispatch( 'loadPlaylists' );
            });
        },
        deleteVideo( context, payload )
        {
            axios.delete( window._rootData.apiUrl + '/playlists/' + payload.playlist + '/' + payload.video ).then( function()
            {
                context.dispatch( 'loadPlaylists' );
            });
        },
        deletePlaylist( context, payload )
        {
            axios.delete( window._rootData.apiUrl + '/playlists/' + payload.playlist ).then( function()
            {
                context.dispatch( 'loadPlaylists' );
            });
        },
        saveSort( context, payload )
        {
            axios.put( window._rootData.apiUrl + '/playlists/' + payload.id, payload ).then( function()
            {
                context.dispatch( 'loadPlaylists' );
            });
        }
    }
});

export default store;