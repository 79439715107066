import { createRouter, createWebHistory } from 'vue-router';

import Landing from './pages/Landing';
import Playlist from './pages/Playlist';
import Manage from './pages/Manage';

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes: [
        { path: '/', component: Landing },
        { path: '/playlist/:slug', component: Playlist },
        { path: '/manage', component: Manage },
    ] // short for `routes: routes`
});

export default router;