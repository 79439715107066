<template>
    <div class="playlist">
        <video :src=" '/storage/' + playlist.videos[index].fsname" ref="player" video autobuffer autoplay @ended="ended" @click.prevent="playPause()"></video>
        <div class="ui prev" v-if="index > 0" @click="prev"></div>
        <div class="ui next" v-if="index < (playlist.videos.length-1)" @click="next"></div>
    </div>
</template>

<script>
    import screenfull from 'screenfull';
    export default {
        data()
        {
            return {
                index: 0
            };
        },
        computed: {
            playlist()
            {
                return this.$store.getters.playlist( this.$route.params.slug );
            }
        },
        mounted()
        {
            document.addEventListener( 'keydown', this.keyPressed );
            if( screenfull.isEnabled )
            {
                screenfull.request();
            }
        },
        beforeUnmount()
        {
            document.removeEventListener( 'keydown' );
        },
        methods: {
            ended()
            {
                this.index++;
            },
            next()
            {
                if( this.index >= ( this.playlist.videos.length - 1 ) ) return;
                this.index++;
            },
            prev()
            {
                if( this.index <= 0 ) return;
                this.index--;
            },
            keyPressed( e )
            {
                if( e.code === 'ArrowLeft' )
                {
                    e.preventDefault();
                    this.prev();
                }
                if( e.code === 'ArrowRight' )
                {
                    e.preventDefault();
                    this.next();
                }
                if( e.code === 'Space' )
                {
                    e.preventDefault();
                    this.playPause();
                }
            },
            playPause()
            {
                this.$refs.player.paused ? this.$refs.player.play() : this.$refs.player.pause();
            }
        }
    }
</script>

<style>
    .playlist {
        overflow: hidden;
        height: 100vh;
        background: #485563; /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #485563, #29323c); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #485563, #29323c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
    video {
        height: 100vh;
        width: 100vw;
        background: black;
    }
    .ui {
        position: absolute;
        top: 25vh;
        height: 50vh;
        width: 20vw;
        opacity: 0;
        cursor: pointer;
    }
    .ui:hover {
        /* opacity: 0.2; */
    }
    .ui.prev {
        left: 0;
        background: transparent url(/arrows.png) no-repeat center center;
        background-size: contain;
        transform: scaleX( -1 );
    }
    .ui.next {
        right: 0;
        background: transparent url(/arrows.png) no-repeat center center;
        background-size: contain;
    }
</style>