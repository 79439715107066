import { createApp } from 'vue';
import store from './store';
import router from './router';
import App from './App.vue';

import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';

store.dispatch( 'loadPlaylists' );

createApp(App)
    .use( VuePlyr, {
        plyr: {}
    })
    .use( store )
    .use( router )
    .mount('#app');
